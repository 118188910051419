var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{staticClass:"myCard1 ml-0 mr-0"},[_c('v-col',{staticClass:"hidden-sm-and-down",attrs:{"md":"4","lg":"3"}},[_c('v-card',{staticClass:"ma-n3 cardHeight",attrs:{"elevation":"1","width":"100%"}},[_c('v-toolbar',{attrs:{"color":"#df6464"}},[_c('v-toolbar-title',{staticStyle:{"color":"white"}},[_vm._v(_vm._s(_vm.$t("draft.recentDraft")))]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":"","color":"white"},on:{"click":_vm.deleteDraft}},[_c('v-icon',[_vm._v("mdi-delete")])],1)],1),_c('v-divider'),(_vm.drafts.length != 0)?_c('v-list',{directives:[{name:"scrollbar",rawName:"v-scrollbar"}],staticClass:"listCard"},_vm._l((_vm.drafts),function(val,key,index){return _c('v-list-item',{key:index,staticClass:"changeColor pa-3",attrs:{"tag":"span","to":{
            name: !val.ConfirmationID ? 'DraftContent' : 'DraftConversation',
            params: !val.ConfirmationID
              ? { id: val.id }
              : { id: val.ConfirmationID, draftid: val.id },
          }},on:{"click":function($event){return _vm.CheckUserID(val.id, val.ConfirmationID)}}},[_c('v-list-item-action',{staticClass:"mr-3"},[_c('v-checkbox',{attrs:{"id":index,"dense":"","value":val,"color":"#df6464"},model:{value:(_vm.checkbox),callback:function ($$v) {_vm.checkbox=$$v},expression:"checkbox"}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(val.DraftName))])],1),_c('v-list-item-action-text',{staticClass:"ml-1"},[_c('span',{staticStyle:{"color":"grey","font-size":"10px"},domProps:{"innerHTML":_vm._s(val.DateCreated)}})])],1)}),1):_c('div',{staticClass:"noDrafts"},[_c('v-img',{staticStyle:{"margin":"auto"},attrs:{"src":require("../assets/draft.png"),"width":"50"}}),_c('b',{staticStyle:{"font-size":"17px","color":"silver"}},[_vm._v(_vm._s(_vm.language == "en" ? "No Draft Found" : "ドラフトが見つかりません"))])],1)],1)],1),_c('v-col',{class:_vm.rowInbox},[_c('v-card',{staticClass:"ma-n3 cardHeight",attrs:{"elevation":"0","width":"100%"}},[_c('div',{staticStyle:{"width":"100%"}},[_c('v-btn',{attrs:{"icon":"","color":"#df6464"},on:{"click":_vm.deleteDraft}},[_c('v-icon',[_vm._v("mdi-delete")])],1)],1),(_vm.drafts.length != 0)?_c('v-list',{directives:[{name:"scrollbar",rawName:"v-scrollbar"}],staticClass:"listCard"},_vm._l((_vm.drafts),function(val,key,index){return _c('v-list-item',{key:index,staticClass:"changeColor pa-3",attrs:{"tag":"span","to":{
            name: !val.ConfirmationID ? 'DraftContent' : 'DraftConversation',
            params: !val.ConfirmationID
              ? { id: val.id }
              : { id: val.ConfirmationID, draftid: val.id },
          }}},[_c('v-list-item-action',{staticClass:"mr-3"},[_c('v-checkbox',{attrs:{"id":index,"dense":"","value":val,"color":"#df6464"},model:{value:(_vm.checkbox),callback:function ($$v) {_vm.checkbox=$$v},expression:"checkbox"}})],1),_c('v-list-item-content',{on:{"click":function($event){return _vm.CheckUserIDFullWidth(val.id)}}},[_c('v-list-item-title',[_vm._v(_vm._s(val.DraftName))])],1),_c('v-list-item-action-text',{staticClass:"ml-1"},[_c('span',{staticStyle:{"color":"grey","font-size":"10px"},domProps:{"innerHTML":_vm._s(val.DateCreated)}})])],1)}),1):_c('div',{staticClass:"noDrafts"},[_c('v-img',{staticStyle:{"margin":"auto"},attrs:{"src":require("../assets/draft.png"),"width":"50"}}),_c('b',{staticStyle:{"font-size":"17px","color":"silver"}},[_vm._v(_vm._s(_vm.language == "en" ? "No Draft Found" : "ドラフトが見つかりません"))])],1)],1)],1),_c('v-col',{class:_vm.rowReply,attrs:{"md":"8","lg":"9"}},[(
        _vm.routerPath == 'Draft/DraftContent' || _vm.routerPath == 'Draft/DraftConversation'
      )?_c('div'):_c('div',{staticClass:"text-center mt-16"},[_c('b',{staticStyle:{"font-size":"23px","color":"silver"}},[_vm._v(_vm._s(_vm.language == "en" ? "No Draft Selected" : "ドラフトが選択されていません"))]),_c('br'),_c('v-img',{staticStyle:{"margin":"auto"},attrs:{"src":require("../assets/empty-email.png"),"width":"100"}})],1),_c('v-btn',{staticClass:"hidden-md-and-up ml-5",attrs:{"color":"error","to":"/Draft"},on:{"click":_vm.showInbox}},[_vm._v("Back")]),_c('router-view')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }