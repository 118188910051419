<template>
  <v-row class="myCard1 ml-0 mr-0">
    <v-col md="4" lg="3" class="hidden-sm-and-down">
      <!-- FOR DESKTOP -->
      <v-card class="ma-n3 cardHeight" elevation="1" width="100%">
        <!-- HEADER -->
        <v-toolbar color="#df6464">
          <v-toolbar-title style="color: white">{{
            $t("draft.recentDraft")
          }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon color="white" @click="deleteDraft">
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </v-toolbar>
        <v-divider></v-divider>
        <!-- DRAFTS -->
        <v-list class="listCard" v-if="drafts.length != 0" v-scrollbar>
          <v-list-item
            v-for="(val, key, index) in drafts"
            :key="index"
            tag="span"
            class="changeColor pa-3"
            :to="{
              name: !val.ConfirmationID ? 'DraftContent' : 'DraftConversation',
              params: !val.ConfirmationID
                ? { id: val.id }
                : { id: val.ConfirmationID, draftid: val.id },
            }"
            @click="CheckUserID(val.id, val.ConfirmationID)"
          >
            <v-list-item-action class="mr-3">
              <v-checkbox
                v-model="checkbox"
                :id="index"
                dense
                :value="val"
                color="#df6464"
              ></v-checkbox>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>{{ val.DraftName }}</v-list-item-title>
            </v-list-item-content>
            <v-list-item-action-text class="ml-1">
              <span v-html="val.DateCreated" style="color: grey; font-size: 10px"></span>
            </v-list-item-action-text>
          </v-list-item>
        </v-list>
        <!-- NO RECORD -->
        <div v-else class="noDrafts">
          <v-img src="../assets/draft.png" width="50" style="margin: auto"> </v-img>
          <b style="font-size: 17px; color: silver">{{
            language == "en" ? "No Draft Found" : "ドラフトが見つかりません"
          }}</b>
        </div>
      </v-card>
    </v-col>

    <!-- FULL WIDTH-->
    <v-col v-bind:class="rowInbox">
      <v-card class="ma-n3 cardHeight" elevation="0" width="100%">
        <!-- HEADER -->
        <div style="width: 100%">
          <v-btn icon color="#df6464" @click="deleteDraft">
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </div>
        <!-- DRAFTS -->
        <v-list class="listCard" v-if="drafts.length != 0" v-scrollbar>
          <v-list-item
            v-for="(val, key, index) in drafts"
            :key="index"
            tag="span"
            class="changeColor pa-3"
            :to="{
              name: !val.ConfirmationID ? 'DraftContent' : 'DraftConversation',
              params: !val.ConfirmationID
                ? { id: val.id }
                : { id: val.ConfirmationID, draftid: val.id },
            }"
          >
            <v-list-item-action class="mr-3">
              <v-checkbox
                v-model="checkbox"
                :id="index"
                dense
                :value="val"
                color="#df6464"
              ></v-checkbox>
            </v-list-item-action>
            <v-list-item-content @click="CheckUserIDFullWidth(val.id)">
              <v-list-item-title>{{ val.DraftName }}</v-list-item-title>
            </v-list-item-content>
            <v-list-item-action-text class="ml-1">
              <span v-html="val.DateCreated" style="color: grey; font-size: 10px"></span>
            </v-list-item-action-text>
          </v-list-item>
        </v-list>
        <!-- NO RECORD -->
        <div v-else class="noDrafts">
          <v-img src="../assets/draft.png" width="50" style="margin: auto"> </v-img>
          <b style="font-size: 17px; color: silver">{{
            language == "en" ? "No Draft Found" : "ドラフトが見つかりません"
          }}</b>
        </div>
      </v-card>
    </v-col>

    <!-- DRAFT CONTENT -->
    <v-col md="8" lg="9" v-bind:class="rowReply"
      ><div
        v-if="
          routerPath == 'Draft/DraftContent' || routerPath == 'Draft/DraftConversation'
        "
      ></div>
      <div class="text-center mt-16" v-else>
        <b style="font-size: 23px; color: silver">{{
          language == "en" ? "No Draft Selected" : "ドラフトが選択されていません"
        }}</b
        ><br />
        <v-img src="../assets/empty-email.png" width="100" style="margin: auto"> </v-img>
      </div>
      <v-btn color="error" @click="showInbox" to="/Draft" class="hidden-md-and-up ml-5"
        >Back</v-btn
      >
      <router-view></router-view>
    </v-col>
  </v-row>
</template>
<script>
import store from "../store";
import moment from "moment";
import attachment from "../functions/Attachment";
import objects from "../functions/alert";
import DraftTable from "../functions/httpRequest/ExternalConnection/DraftTable";

export default {
  data() {
    return {
      routerPath: "",
      userID: "",
      name: "",
      rowInbox: "hidden-md-and-up",
      rowReply: "hidden-sm-and-down",
      checkbox: [],
      drafts: [],
    };
  },
  created() {
    // CALL FUNCTION FOR GETING USER DRAFT
    this.getData();

    // USER TYPE
    if (store.state.userinfo.EmployeeNumber) {
      this.userID = store.state.userinfo.EmployeeNumber;
      this.name =
        store.state.userinfo.FirstNameEng + " " + store.state.userinfo.LastNameEng;
    } else {
      this.name = store.state.userinfo.FullName;
      this.userID = store.state.userinfo.EmployeeCode;
    }
  },
  computed: {
    //*********************//
    //  LANGEUAGE LOCALE   //
    //*********************//
    language() {
      return this.$i18n.locale;
    },
  },
  //*********************************//
  //  WATCH ROUTES CHAGING IN DRAFT  //
  //*********************************//

  watch: {
    $route(to) {
      const toPath = to.path.split("/");
      this.routerPath = toPath[1] + "/" + toPath[2];
      console.log(this.routerPath, "170");
    },
  },
  methods: {
    //*********************************//
    //  GET DRAFT ID FOR MOBILE SIZE   //
    //*********************************//

    CheckUserID(val, val1) {
      this.DraftID(val);
      this.ReplyID(val1);
    },
    //**********************************//
    //  GET DRAFT ID FOR DESKTOP SIZE   //
    //**********************************//

    CheckUserIDFullWidth(val) {
      this.DraftID(val);
      this.rowInbox = "hidden-sm-and-down";
      this.rowReply = "hidden-md-and-up";
    },
    //************************//
    //  GET DRAFTS TABLE API  //
    //************************//

    getData() {
      // GET DRAFT
      DraftTable.Get().then((res) => {
        // console.log(res.data);
        this.drafts = res.data.filter((rec) => {
          return rec.UserID == this.userID;
        });
        // INSERT DRAFT NUM AND VALIDATE DATE
        for (let x in this.drafts) {
          this.drafts[x].Num = x;
          this.drafts[x].DateCreated = moment(this.drafts[x].DateCreated).format(
            "YYYY-MM-DD"
          );
        }
      });
    },
    //************************//
    //     DELETE DRAFTSs      //
    //************************//

    deleteDraft() {
      // CHECK FOR SELECTED DRAFT
      if (this.checkbox.length < 1) {
        this.$toast.error(this.$t("draft.SelectDraft"), objects.alert);
      } else {
        // CONFIRM TO DELETE
        this.$confirm({
          title: this.$t("dialog.titleDraft"),
          message: this.$t("dialog.messageDraft"),
          button: {
            no: this.$t("dialog.no"),
            yes: this.$t("dialog.yes"),
          },
          callback: async (confirm) => {
            let dbDelete = [];
            if (confirm) {
              // REMOVE SELECTED DRAFT
              for (let x in this.checkbox) {
                this.drafts.splice(this.checkbox[x].Num, 1);
                for (let x in this.drafts) {
                  this.drafts[x].Num = x;
                }
                dbDelete.push(this.checkbox[x].id);
              }
              // CHECK IF WITH ATTACHMENT
              await DraftTable.CheckImage(dbDelete).then((res) => {
                if (res.data != "No Attachment Found") {
                  let attach = [];
                  let splitdata = [];
                  for (let x in res.data) {
                    splitdata = res.data[x].Attachment.split(",");
                    for (let i of splitdata) {
                      attach.push({
                        pathName: `${res.data[0].Path}/${i}`,
                      });
                    }
                  }
                  // PASS INFO TO DELETE
                  attachment.singleDelete(attach);
                }
              });
              // REMOVE FROM THE DATABASE
              await DraftTable.Delete(dbDelete).then(() => {
                this.$toast.success(this.$t("draft.successDelete"), objects.alert);
              });

              for (let x in this.drafts) {
                this.drafts[x].Num = x;
              }
              this.checkbox = [];
              this.$router.push("/Draft");
            }
          },
        });
      }
    },
    //************************//
    //  FOR RESPONSIVENESS    //
    //************************//
    showInbox() {
      this.rowInbox = "hidden-md-and-up";
      this.rowReply = "hidden-sm-and-down";
    },
  },
};
</script>
<style lang="scss">
.listCard {
  max-height: calc(90vh - 128px);
}

.cardHeight {
  max-height: calc(90vh - 124px);
  min-height: calc(90vh - 56px);
}
.changeColor {
  background-color: #ffffff;
  cursor: pointer;
}
.changeColor:hover {
  background-color: #e3e0e0;
}
.dialogStyle {
  button {
    color: #df6464;
  }
}
.noDrafts {
  width: 100%;
  color: grey;
  text-align: center;
  width: 100%;
  padding-top: 25vh;
}
</style>
